<script setup lang="ts">
  import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
  
  const props = defineProps<{
    modelValue: boolean
    text?: string
    detail?: string
    isShort?: boolean
  }>()

  const emit = defineEmits<{
    (event: 'update:modelValue', payload: typeof props.modelValue): void
  }>()

  const internIsEnabled = ref<boolean>(props.modelValue)
  watch(internIsEnabled, () => {
    emit('update:modelValue', internIsEnabled.value)
  })
</script>

<template>
  <SwitchGroup as="div" class="flex items-center gap-x-3">
    <Switch v-if="!isShort" v-model="internIsEnabled" :class="[internIsEnabled ? 'bg-sky-blue-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-blue-500 focus:ring-offset-2']">
      <span aria-hidden="true" :class="[internIsEnabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
    </Switch>
    <Switch v-else v-model="internIsEnabled" class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-sky-blue-600">
      <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md" />
      <span aria-hidden="true" :class="[internIsEnabled ? 'bg-sky-blue-500' : 'bg-sky-blue-800', 'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out']" />
      <span aria-hidden="true" :class="[internIsEnabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out']" />
    </Switch>
    <SwitchLabel v-if="text || detail || $slots.text" as="span" class="cursor-pointer">
      <span class="text-sm font-medium text-gray-900">
        <slot name="text">{{ text }}</slot>
      </span>
      <span v-if="detail" class="ml-2 text-sm text-gray-500">({{ detail }})</span>
    </SwitchLabel>
  </SwitchGroup>
</template>